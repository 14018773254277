<template>
    <sm-editable-list
      :controllerName="controllerName"
      :breadcrumbs="breadcrumbs"
      :tableCaption="tableCaption"
      :tableHeaders="tableHeaders"
      showCreateButton
      showEditButton
      showDeleteButton
      @create="onCreate('AccountsToBlockClientMessageCreate')"
      @edit="(id) => onEdit('AccountsToBlockClientMessageEdit', id)"
    />
  </template>
  
  <script>
  // mixins
  import editableList from '@/mixins/editableList.js';
  // components
  import SmEditableList from '@/components/views/SmEditableList.vue';
  
  export default {
    name: 'AccountsToBlockClientMessages',
  
    components: {
      SmEditableList,
    },
  
    mixins: [editableList],
  
    data() {
      return {
        controllerName: 'AccountsToBlockClientMessages',
        tableCaption: 'Сотрудники, которым нельзя писать в заявку КСП',
        breadcrumbs: [
          {
            text: 'Администрирование',
            route: { name: 'AdministrationMain' },
          },
          {
            text: 'Сотрудники, которым нельзя писать в заявку КСП',
          },
        ],
        tableHeaders: [
          { text: 'ФИО сотрудника', alias: 'accountName', order: 'accountName' },
          { alias: 'actions' },
        ],
      };
    },
  };
  </script>
  